import "./App.css";
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ComingSoon from "./components/ComingSoon";
import LandingPage from "./components/LandingPage";
import Education from "./components/Education";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/coming-soon">
            <ComingSoon />
          </Route>
          {/* <Route path="/education">
            <Education />
          </Route> */}
          <Route path="/">
            <LandingPage></LandingPage>
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;

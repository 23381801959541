
import React, {useRef, useEffect} from 'react'
import VanillaTilt from 'vanilla-tilt'
import {Tooltip, Grid} from '@material-ui/core'
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";
import EmailIcon from "@material-ui/icons/Email";
import GetAppIcon from "@material-ui/icons/GetApp";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {Link} from "react-router-dom"


export default function Menu({selected="education"}) {
    const options = { max: 10, speed: 400, glare: true, "max-glare": 1}
    return (
        <div className="container">
            <Tilt className="card" options={options}>
              <div className="content" style={{fontSize: '2.5em !important'}}>
                {/* <h2>nds</h2> */}
                <h4 style={selected === 'education' ?  {textDecoration: 'underline'} : {}}>Education</h4>
                <h4>Skills</h4>
                <h4>Experience</h4>
                <h4>Projects</h4>
                <h4>Awards</h4>
                <h4>Contact</h4>
                <Link to='/'><h4 style={{textDecoration: 'none !important'}}>Exit</h4></Link>
                {/* Social Tray */}

                <Grid container space={5}  style={{marginTop: '15px'}}>
                  <Grid item xs={3}>
                    <LightTooltip placement="top" title="My LinkedIn">
                      <a
                        href="https://www.linkedin.com/in/nicolas-ds/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        <LinkedInIcon className="menu-links" />
                      </a>
                    </LightTooltip>
                  </Grid>
                  <Grid item  xs={3}>
                    <LightTooltip placement="top" title="My Github">
                      <a
                        href="https://www.github.com/nicolasdossantos/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <GitHubIcon className="menu-links"></GitHubIcon>
                      </a>
                    </LightTooltip>
                  </Grid>
                  <Grid item xs={3}>
                    <LightTooltip placement="top" title="Email Me">
                      <a
                        href="mailto:nicolas182@icloud.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <EmailIcon className="menu-links"></EmailIcon>
                      </a>
                    </LightTooltip>
                  </Grid>

                  <Grid item xs={3}>
                    <LightTooltip placement="top" title="Download Resume">
                      <a
                        href="https://www.linkedin.com/in/nicolas-ds/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <GetAppIcon className="menu-links"></GetAppIcon>
                      </a>
                    </LightTooltip>
                  </Grid>
                </Grid>

                <br></br>
                {/* <Link to={buttonUrl}>{buttonText}</Link> */}
              </div>
            </Tilt>
          </div>
    )
}
function Tilt(props) {
  const { options, ...rest } = props;
  const tilt = useRef(null);

  useEffect(() => {
    console.log(tilt.current);
    VanillaTilt.init(tilt.current, options);
  }, [options]);

  return <div ref={tilt} {...rest} />;
}

const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "rgba(255, 255, 255, 0.14)",
      fontWeight: "500",
      color: "rgba(0,0,0,0.5)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
      marginBottom: "22px",
    },
  }))(Tooltip);

import React from 'react'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import {Box} from '@material-ui/core'
import {

    Link
  } from "react-router-dom";

export default function ComingSoon() {
    return (
        <>
        <Box display={{ xs: 'block', md: 'none' }} >
        <div style={{fontSize:'3em', display:'flex', alignContent: 'center', justifyContent:'center', marginTop:'70px'}}>
            COMING SOON
        </div>
        <div style={{display:'flex', alignContent: 'center', justifyContent:'center'}}><Link to="/"><KeyboardBackspaceIcon className="resume-button" style={{fontSize:'3em', width: '100px'}}></KeyboardBackspaceIcon></Link></div>
        </Box>
        <Box display={{ xs: 'none', md: 'block' }} >
        <div style={{fontSize:'8em', display:'flex', alignContent: 'center', justifyContent:'center'}}>
            COMING SOON
        </div>
        <div style={{display:'flex', alignContent: 'center', justifyContent:'center'}}><Link to="/"><KeyboardBackspaceIcon className="resume-button" style={{fontSize:'8em', width: '300px'}}></KeyboardBackspaceIcon></Link></div>
        </Box>
        
        </>
    )
}

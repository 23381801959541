import React, { useEffect, useRef } from "react";
import VanillaTilt from "vanilla-tilt";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";
import EmailIcon from "@material-ui/icons/Email";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Grid, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

export default function LandingPage() {
  const options = {
    max: 25,
    speed: 400,
    glare: true,
    "max-glare": 1,
  };

  return (
    <div className="landing-page">
    <Box display={{ xs: 'block', md: 'none' }} style={{marginTop: '-100px'}}>
    <Grid container justify="center">
        <Grid item>
          <Grid container direction="column" className="title">
            <Grid item style={{}}>
              <div style={{ fontSize: "3em", fontWeight: 400, lineHeight: '.8' }}>
                Hello, I am
              </div>
            </Grid>
            <Grid item>
              <div style={{ fontSize: "6em" }}>
                <span style={{ fontWeight: "bold", lineHeight: '.8' }}> Nick</span>{" "}
              </div>
            </Grid>
            <Grid item>
              <div style={{ fontSize: "3em", lineHeight: '.8' }}>
                <span> dos Santos</span>{" "}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <div className="container">
            <Tilt className="card"  options={options}>
              <div className="content">
                {/* <h2>NDS</h2> */}

                <div style={{fontSize: "3em", fontWeight: '600', lineHeight:'1'}}>FullStack Software Engineer</div>
                <br></br>

                <Grid container space={2} justify="space-evenly">
                  <Grid item>
                    <LightTooltip placement="top" title="My LinkedIn">
                      <a
                        href="https://www.linkedin.com/in/nicolas-ds/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        <LinkedInIcon className="links" />
                      </a>
                    </LightTooltip>
                  </Grid>
                  <Grid item>
                    <LightTooltip placement="top" title="My Github">
                      <a
                        href="https://www.github.com/nicolasdossantos/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <GitHubIcon className="links"></GitHubIcon>
                      </a>
                    </LightTooltip>
                  </Grid>
                  <Grid item>
                    <LightTooltip placement="top" title="Email Me">
                      <a
                        href="mailto:nicolas182@icloud.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <EmailIcon className="links"></EmailIcon>
                      </a>
                    </LightTooltip>
                  </Grid>

                  <Grid item>
                    <LightTooltip placement="top" title="Download Resume">
                      <a
                        href="https://drive.google.com/uc?export=download&id=1m3ydc3VdFG_4V8imOfLkdXQxcoWAl3eE"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <GetAppIcon className="links"></GetAppIcon>
                      </a>
                    </LightTooltip>
                  </Grid>
                </Grid>

                <Link className="resume-button" to="/coming-soon">
                  Interactive Resume
                </Link>
              </div>
            </Tilt>
          </div>
        </Grid>
      </Grid>


    </Box>
    <Box display={{ xs: 'none', md: 'block' }}>
      <Grid container justify="center">
        <Grid item style={{ paddingTop: "90px" }}>
          <Grid container direction="column" className="title">
            <Grid item>
              <div style={{ fontSize: "4em", fontWeight: 400 }}>
                Hello, I am
              </div>
            </Grid>
            <Grid item>
              <div style={{ fontSize: "8em" }}>
                <span style={{ fontWeight: "bold" }}> Nick</span>{" "}
              </div>
            </Grid>
            <Grid item>
              <div style={{ fontSize: "4em" }}>
                <span> dos Santos</span>{" "}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <div className="container">
            <Tilt className="card" options={options}>
              <div className="content">
                {/* <h2>NDS</h2> */}

                <h3>FullStack Software Engineer</h3>
                <br></br>

                <Grid container space={2} justify="space-evenly">
                  <Grid item>
                    <LightTooltip placement="top" title="My LinkedIn">
                      <a
                        href="https://www.linkedin.com/in/nicolas-ds/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        <LinkedInIcon className="links" />
                      </a>
                    </LightTooltip>
                  </Grid>
                  <Grid item>
                    <LightTooltip placement="top" title="My Github">
                      <a
                        href="https://www.github.com/nicolasdossantos/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <GitHubIcon className="links"></GitHubIcon>
                      </a>
                    </LightTooltip>
                  </Grid>
                  <Grid item>
                    <LightTooltip placement="top" title="Email Me">
                      <a
                        href="mailto:nicolas182@icloud.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <EmailIcon className="links"></EmailIcon>
                      </a>
                    </LightTooltip>
                  </Grid>

                  <Grid item>
                    <LightTooltip placement="top" title="Download Resume">
                      <a
                        href="https://drive.google.com/uc?export=download&id=1m3ydc3VdFG_4V8imOfLkdXQxcoWAl3eE"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <GetAppIcon className="links"></GetAppIcon>
                      </a>
                    </LightTooltip>
                  </Grid>
                </Grid>

                <Link className="resume-button" to="/coming-soon">
                  Interactive Resume
                </Link>
              </div>
            </Tilt>
          </div>
        </Grid>
      </Grid>
      </Box>
    </div>
  );
}

function Tilt(props) {
  const { options, ...rest } = props;
  const tilt = useRef(null);

  useEffect(() => {
    VanillaTilt.init(tilt.current, options);
  }, [options]);

  return <div ref={tilt} {...rest} />;
}

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "rgba(255, 255, 255, 0.14)",
    fontWeight: "500",
    color: "rgba(0,0,0,0.5)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    marginBottom: "22px",
  },
}))(Tooltip);
